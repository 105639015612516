import storage from "store"
import { ACCESS_TOKEN, LOGIN_INFO } from '@/config/constant'

const user = {
  state: {
    token: '',
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 获取本地信息
    GetLocalInfo({ commit, state }) {
      if (!state.token) {
        const token = storage.get(ACCESS_TOKEN)
        if (token) {
          commit('SET_TOKEN', token)
        }
        const info = storage.get(LOGIN_INFO)
        if (info) {
          commit('SET_INFO', info)
        }
      }
    },

    // 登出
    Logout ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_INFO', {})
        storage.remove(ACCESS_TOKEN)
        storage.remove(LOGIN_INFO)

        resolve()
      })
    }

  }
}

export default user
