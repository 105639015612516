import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import apiKeys from "./config/apiKeys";

// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";
import fullScreenContainer from "./components/fullScreenContainer";

// 提示toast
import Msg from "vue-message";

// 图片预览
import hevueImgPreview from "hevue-img-preview";

import "ant-design-vue/dist/antd.css";
// 引入全局css
import "./assets/less/style.less";
import BasicLayout from "./components/BasicLayout.vue";
import store from "./store/index";
import "./permission";

Vue.use(Antd);
Vue.use(dataV);
Vue.use(fullScreenContainer);

Vue.use(Msg, {
  text: "欢迎访问数据大屏",
  position: "top",
  duration: 3000,
  background: "rgba(13, 128, 243, 0.6)",
});

Vue.use(hevueImgPreview);

Vue.component("basic-layout", BasicLayout);

Vue.config.productionTip = false;

window._AMapSecurityConfig = {
  securityJsCode: apiKeys.securityJsCode,
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
