/**
 * 系统设置相关接口（登录、用户等）
 */
import request from "../utils/request";

/**
 * 用户登录 - 密码登录
 * @param {*} parameter
 * @returns
 */
export function userPwdLogin(parameter) {
  return request({
    url: "/login/password",
    method: "post",
    data: parameter,
    noAuth: true,
  });
}

/**
 * 用户登录 - 验证码登录
 * @param {*} parameter
 * @returns
 */
export function userCodeLogin(parameter) {
  return request({
    url: '/login/sendSms',
    method: 'post',
    data: parameter,
    noAuth: true
  })
}

/**
 * 获取验证码
 * @param {*} parameter
 * @returns
 */
export function getSmsCaptcha(parameter) {
  return request({
    url: '/sms/sendVerifyCode',
    method: 'post',
    data: parameter,
    noAuth: true
  })
}

/**
 * 修改密码
 * @param {*} parameter
 * @returns
 */
export function updatePassword(parameter) {
  return request({
    url: '/login/updatePassword',
    method: 'post',
    data: parameter,
  })
}

/**
 * 获取企业信息
 * @param {*} parameter
 * @returns
 */
export function getCompanyInfo(parameter) {
  return request({
    url: '/datacenter/companyInfo/getCompanyInfo',
    method: 'get',
    params: parameter,
  })
}


/**
 * 获取企业申请信息
 * @param {*} parameter
 * @returns
 */
export function getCompanyRequestInfo(parameter) {
  return request({
    url: '/datacenter/mapCompanyRequest/getCompanyRequestByUserId',
    method: 'get',
    params: parameter,
  })
}

/**
 * 企业用户申请
 * @param {*} parameter
 * @returns
 */
export function mapCompanyRequest(parameter) {
  return request({
    url: '/datacenter/mapCompanyRequest/add',
    method: 'post',
    data: parameter,
  })
}

/**
 * 企业用户修改
 * @param {*} parameter
 * @returns
 */
export function mapCompanyUpdate(parameter) {
  return request({
    url: '/datacenter/mapCompanyRequest/updateForUser',
    method: 'post',
    data: parameter,
  })
}


/**
 * 查询行业分类
 * @param {*} parameter 
 * @returns 
 */
export function industryTypePage(parameter) {
  return request({
    url: '/datacenter/getIndustry',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询企业分类
 * @param {*} parameter 
 * @returns 
 */
export function companyTypePage(parameter) {
  return request({
    url: '/datacenter/getCompanyType',
    method: 'get',
    params: parameter
  })
}
