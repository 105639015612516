<template>
  <div>
    <div class="top-header">
      <h2 class="tit">产业数字地图</h2>
      <div class="right-side">
        <a-dropdown>
          <div class="user-box">
            <div class="avatar-box">
              <a-icon type="user" />
            </div>
            <div>{{ $store.getters.userPhone }}</div>
          </div>
          <a-menu slot="overlay">
            <a-menu-item @click="openPasswordModal">
              <a-icon type="safety-certificate" />
              修改密码
            </a-menu-item>
            <a-menu-item @click="logoutHandle">
              <a-icon type="poweroff" />
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <ResetPassword :visible="resetPasswordVisible" @cancel="resetPasswordVisible = false" />
  </div>
</template>

<script>
import ResetPassword from './ResetPassword.vue'
export default {
  components: {
    ResetPassword
  },
  data() {
    return {
      resetPasswordVisible: false
    };
  },
  methods: {
    openPasswordModal() {
      this.resetPasswordVisible = true
    },
    logoutHandle() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push('/login')
      })
    },
  },
};
</script>

<style lang="less" scoped>
.top-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  .tit {
    margin: 0;
    padding: 0 24px;
    font-size: 18px;
  }

  .right-side {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .user-box {
    height: 100%;
    cursor: pointer;
    margin-right: 8px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    color: #333;
  }
  .avatar-box {
    margin-right: 6px;
    width: 32px;
    height: 32px;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
