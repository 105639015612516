import router from './router'
import storage from 'store'
import { ACCESS_TOKEN } from '@/config/constant'
import store from './store'
const allowList = ['/login'] // no redirect allowList

router.beforeEach((to, from, next) => {
  /* has token */
  if (storage.get(ACCESS_TOKEN)) {
    // 获取本地信息 -> store
    store.dispatch('GetLocalInfo')

    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (allowList.includes(to.path)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } })
    }
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})
