<template>
  <div class="basic-layout-wrapper">
    <TopHeader />
    <LeftMenu />
    <div class="layout-content">
      <div class="page-wrapper" v-if="isRouterShow">
        <BreadCrumb />
        <slot></slot> <!-- 插槽出口 -->
      </div>
    </div>
  </div>
</template>

<script setup>
  import TopHeader from '../components/TopHeader.vue'
  import LeftMenu from '../components/LeftMenu'
  import BreadCrumb from '../components/BreadCrumb.vue'
  import { nextTick, provide, ref } from "vue";

  const isRouterShow = ref(true);
  const reload = () => {
    isRouterShow.value = false;
    nextTick(() => {
      isRouterShow.value = true;
    });
  };

  provide("reload", reload);
</script>

<style lang="less" scoped>
.layout-content {
  background-color: #f2f3f5;
  padding: 60px 0 0 256px;
  min-height: 100vh;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
}
.page-wrapper {
  padding: 0 20px 20px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  // .page-content {
  //   height: 100%;
  //   flex: 1;
  // }
}
</style>