<template>
  <div class="left-menu">
    <div class="left-sider-menu">
      <a-menu
        :selectedKeys="selectedKeys"
        :style="{ width: '100%', height: '100%' }"
        mode="inline"
        @click="clickMenuItem"
      >
        <a-menu-item v-for="item in memus"  :key="item.name || item.path">
          <a-icon :type="item.meta.icon" /> {{ item.meta.title }}
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedKeys: [],
      memus: [
        {
          path: '/company',
          name: '/company',
          meta: { title: '企业资料', icon: 'home' }
        },
        {
          path: '/datav',
          name: '/datav',
          meta: { title: '数字地图', icon: 'pie-chart' }
        }
      ]
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.selectedKeys = [route.name || route.path]
      },
      immediate: true
    }
  },
  methods: {
    clickMenuItem({key}) {
      console.log('click', key);
      if (key === this.$route.name) return
      if (/http(s)?:/.test(key)) {
        window.open(key);
      } else {
        if (key === '/datav') {
          window.open(key);
        } else {
          this.$router.push({ name: key });
        }
      }
    }
  }
}
</script>

<style lang="less">
.left-menu {
  position: fixed;
  left: 0;
  top: 60px;
  width: 256px;
  height: calc(100vh - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: all .2s;
  .collapsed-btn {
    position: absolute;
    left: 24px;
    bottom: 10px;
    cursor: pointer;
  }
  .left-sider-menu {
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #eee;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
    }
  }
  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    opacity: 0;
  }
}
</style>
