import axios from 'axios'
import { createHeaders } from './tool'
import { notification } from 'ant-design-vue'
import router from '@/router'
import store from '@/store'

/**
 * 所有环境的api地址前缀
 */
const ALL_ENV_BASEURL = {
  dev: 'http://10.10.1.145:8666',
  test: 'https://api.test.ysintelligent.com/epivg/map',
  prod: 'https://api.ysintelligent.com//epivg/map',
}

/**
 * 所有环境的oss资源地址前缀
 */
const ALL_ENV_OSSURL = {
  dev: 'http://10.10.1.240:8888',
  test: 'https://oss.test.gyfzpt.com',
  prod: 'https://oss.gyfzpt.com'
}


export const baseURL = ALL_ENV_BASEURL[process.env.VUE_APP_API_ENV]
export const ossURL = ALL_ENV_OSSURL[process.env.VUE_APP_API_ENV]

/**
 * 接口请求统一处理
 * @param {*} baseURL 
 * @param {*} accessKey 
 * @param {*} secretKey 
 * @returns 
 */
function createRequest(baseURL) {
  // 创建axios实例
  const request = axios.create({
    // API 请求的默认前缀
    baseURL: baseURL,
    // 请求超时时间
    timeout: 10000,
  })

  request.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    // console.log(config)
    config.headers = {
      ...config.headers,
      ...createHeaders(),
    }
    // console.log(config)
    return config
  }, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  })

  request.interceptors.response.use(response => {
    // console.log('response', response)
    if (!response.data) {
      notification.error({
        message: '错误提示',
        description: '网络异常，请稍后重试',
        duration: 3
      })
    } else {
      if (response.data.code === 401) {
        store.dispatch('Logout').then(() => {
          router.push('/login');
        })
      }
    }
    return response.data
  }, error => {
    console.log('error', error)
    if (error.response?.status === 401) {
      store.dispatch('Logout').then(() => {
        router.push('/login');
      })
    } else {
      notification.error({
        message: '错误提示',
        description: '网络异常，请稍后重试',
        duration: 3
      })
    }
    return Promise.reject(error)
  })

  return request
}

const request = createRequest(baseURL)

export default request
