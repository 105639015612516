import Vue from 'vue'
import Router from 'vue-router'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // 首页
    {
      path: '/',
      redirect: '/company'
    },
    {
      path: '/company',
      name: '/company',
      component: () => import('@/views/company/index.vue'),
      meta: { title: '企业资料' }
    },
    {
      path: '/datav',
      name: '/datav',
      component: () => import('@/views/datav/index.vue'),
      meta: { title: '数字地图' }
    },
    {
      path: '/login',
      name: '/login',
      component: () => import('@/views/user/login.vue'),
      meta: { title: '登录/注册' }
    },
  ],
})

export default router