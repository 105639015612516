<template>
  <!-- 修改密码 -->
  <a-modal
    :visible="visible"
    title="修改密码"
    @ok="submitHandle"
    @cancel="$emit('cancel');"
    :maskClosable="false"
    :width="640"
  >
    <a-spin :spinning="loading">
      <a-alert message="温馨提示" type="warning" show-icon>
        <template v-slot:description>
          建议你选择容易记住、但对他人难以猜测的密码，例如字母和数字的组合。
          <!-- <br /> -->
          <!-- 修改成功后，将退出登录，使用新密码重新登录。请谨慎操作！ -->
        </template>
      </a-alert>
      <br />
      <a-form-model
        ref="modalForm"
        :model="formState"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
      >
        <!-- <a-form-model-item label="手机号">
          {{ userPhone }}
        </a-form-model-item>

        <a-form-model-item
          label="验证码"
          prop="code"
          :rules="[{ required: true, message: '请输入验证码' }]"
        >
          <a-input
            style="width: 70%"
            v-model="formState.code"
            placeholder="验证码"
          ></a-input>

          <a-button
            style="width: 28%; margin-left: 2%"
            :disabled="captchaState.sendBtn"
            @click="getCaptcha"
          >
            {{ !captchaState.sendBtn ? "获取验证码" : captchaState.time + "s" }}
          </a-button>
        </a-form-model-item> -->

        <a-form-model-item
          label="新密码"
          prop="password"
          :rules="[
            { required: true, message: '请输入新密码', trigger: 'change' },
            {
              pattern: /^[a-zA-Z0-9_!@#$%^&*`~()-+=]{6,18}$/,
              message: '密码可以是字母、数字、部分特殊符号，6-18位',
              trigger: 'change',
            },
          ]"
        >
          <a-input-password
            v-model.trim="formState.password"
            placeholder="请输入新密码"
          ></a-input-password>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { getSmsCaptcha, updatePassword } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userPhone"]),
  },
  data() {
    return {
      loading: false,
      formState: {
        phoneNumber: "",
        password: "",
        code: "",
      },
      captchaState: {
        time: 60,
        sendBtn: false,
      },
    };
  },
  methods: {
    getCaptcha() {
      this.captchaState.sendBtn = true;

      const interval = window.setInterval(() => {
        if (this.captchaState.time-- <= 0) {
          this.captchaState.time = 60;
          this.captchaState.sendBtn = false;
          window.clearInterval(interval);
        }
      }, 1000);

      const hide = this.$message.loading("验证码发送中...", 0);
      getSmsCaptcha({ cellphone: this.userPhone })
        .then((res) => {
          setTimeout(hide, 1);
          if (res.code === 200) {
            this.$notification.success({
              message: "提示",
              description: "验证码发送成功",
              duration: 2.5,
            });
          } else {
            this.$message.error(res.msg || "验证码发送失败");
          }
        })
        .catch((err) => {
          console.error(err);
          setTimeout(hide, 1);
          clearInterval(interval);
          this.captchaState.time = 60;
          this.captchaState.sendBtn = false;
        });
    },
    submitHandle() {
      this.$refs.modalForm.validate((valid) => {
        if (valid) {
          updatePassword({
            // code: this.formState.code,
            password: this.formState.password,
            phoneNumber: this.userPhone,
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.$refs.modalForm.resetFields();
                this.$emit("cancel");
              } else {
                this.$message.error(res.msg || "操作失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
// import { ref, reactive, defineEmits, defineExpose} from 'vue'
// import { resetPassword } from "@/api/user";
// import { message } from "ant-design-vue";

// const modalForm = ref(null);
// const loading = ref(false);
// const visible = ref(false);
// const formState = reactive({
//   inputPassword: "",
// });
// const emit = defineEmits(["finish"]);

// const showModal = () => {
//   console.log("showModal");
//   visible.value = true;
// };

// const submitApi = (params) => {
//   loading.value = true;
//   resetPassword({
//     ...params,
//     userId: '',
//   })
//     .then((res) => {
//       loading.value = false;
//       if (res.code === 200) {
//         message.success("操作成功， 请重新登录系统");
//         visible.value = false;
//         modalForm.value.resetFields();
//         emit("finish");
//       } else {
//         message.error(res.msg);
//       }
//     })
//     .catch((err) => {
//       loading.value = false;
//       console.error(err);
//     });
// };

// const submitHandle = () => {
//   console.log(modalForm);
//   modalForm.value
//     .validate()
//     .then((values) => {
//       console.log(values);
//       submitApi(values);
//     })
//     .catch((err) => {
//       console.error(err);
//     });
// };
</script>

<style lang="less" scoped></style>
