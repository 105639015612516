<template>
  <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item>产业数字地图</a-breadcrumb-item>
    <a-breadcrumb-item>{{ $route.meta?.title }}</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      
    }
  }
}
// import { useRoute } from "vue-router";

// const route = useRoute();
// const breadcrumblist = route.matched
//   ?.slice(1)
//   .filter((item) => !item.meta?.hiddenInCrumb)
//   .filter((item, index, arr) => {
//     if (arr.length - 1 === index && arr.length >= 2) {
//       if (
//         item.meta?.title === arr[index - 1].meta?.title &&
//         item.meta?.hidden
//       ) {
//         return false;
//       }
//     }
//     return true;
//   });
// console.log(breadcrumblist);
</script>

<style lang="less" scoped>
.breadcrumb {
  margin: 16px 0;
}
</style>
